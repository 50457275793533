/* *{
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: normal;
}

body{
    background: #277781;
}

.heading{
    color: #444;
    font-size: 40px;
    text-align: center;
    padding: 10px;
} */

/* .product-btn{
    overflow: hidden;
    background: #aaa6a9 ;
    color: #071423;
    text-align: center;
    font-size: 18px;
    width: 20px;
    height: 1px;
    padding: 1.5rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin: 2rem;
} */

.container-video{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    align-items: flex-start;
    padding: 5px 5%;
}

.container-video .main-video{
    background: #fff;
    border-radius: 5px;
    padding: 10px;
}

.container-video .main-video video{
    width: 100%;
    border-radius: 5px;
}

.container-video .main-video .title{
    color: #444;
    font-size: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.container-video .video-list{
    background: #fff;
    border-radius: 5px;
    height: 520px;
    overflow-y: scroll;
}

.container-video .video-list::-webkit-scrollbar{
    width: 7px;
}
.container-video .video-list::-webkit-scrollbar-track{
    background: #ccc;
    border-radius: 50px;
}
.container-video .video-list::-webkit-scrollbar-thumb{
    background: #666;
    border-radius: 50px;
}

.container-video .video-list .vid video{
    width: 100px;
    border-radius: 5px;
}

.container-video .video-list .vid{
    display: flex;
    align-items: center;
    gap: 15px;
    background: #dfe8eb;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.1);
    cursor: pointer;
}

.container-video .video-list .vid:hover{
    background: #eee;
}

.container-video .video-list .vid.active{
    background: #2583b1;
}

.container-video .video-list .vid.active .title{
    color: #fff;
}

.container-video .video-list .vid .title{
    color: #333;
    font-size: 17px;
}

@media(max-width:991px) {
    .container-video{
        grid-template-columns: 1.5fr 1fr;
        padding: 10px;
    }
    .product-btn{
        font-size:14px;
        width: 10px;
        height: 1px;
        padding: 1.5rem;       
        border-radius: 1rem;
        margin: 2rem;
    }
}

@media(max-width:768px) {
    .container-video{
        grid-template-columns: 1fr;
        padding: 10px;
    }
}
