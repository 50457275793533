@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, #047180, #d4ae37);
}

@tailwind base;
@tailwind components;
@tailwind utilities;